import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PersonalInfo from "../personal/personal-form";
import Paper from "@material-ui/core/Paper";
import PaymentInfo from "../payment-info/payment-info";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./step-four.css";

class FormStepFour extends Component {
  render() {
    return (
      <div className="user-edit">
        <CircularProgress size={120} className={`preloader-end ${this.props.loaderVisible}`} />

        <PersonalInfo
          childInfo={this.props.childInfo}
          handleChangePersonalInfo={this.props.handleChangePersonalInfo}
        />

        <PaymentInfo
          payReqInfo={this.props.payReqInfo}
          paySelInfo={this.props.paySelInfo}
          apiFieldsPayments={this.props.apiFieldsPayments}
        />
      </div>
    );
  }
}

export default FormStepFour;
