import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputMask from "react-input-mask";

import "./personal-form.css";

class PersonalForm extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="column">
          <TextField
            id="studentId"
            name="studentId"
            label="מספר תעודת זהות"
            margin="normal"
            fullWidth={true}
            value={this.props.childInfo.studentId || ""}
            disabled={true}
            classes={{ root: "input-rtl" }}
            onChange={this.props.handleChangePersonalInfo}
          />
          <br />
          <TextField
            id="studentFirstName"
            name="studentFirstName"
            label="שם פרטי"
            margin="normal"
            fullWidth={true}
            value={this.props.childInfo.studentFirstName || ""}
            disabled={true}
            classes={{ root: "input-rtl" }}
            onChange={this.props.handleChangePersonalInfo}
          />
          <br />
          <TextField
            id="studentLastName"
            name="studentLastName"
            label="שם משפחה"
            margin="normal"
            fullWidth={true}
            value={this.props.childInfo.studentLastName || ""}
            disabled={true}
            classes={{ root: "input-rtl" }}
            onChange={this.props.handleChangePersonalInfo}
          />
        </div>
        <div className="column">
        <p>יש להזין לפחות כתובת אימייל אחת ומספר טלפון אחד</p>
          <InputMask
            mask="999-9999999"
            value={this.props.childInfo.parentOnePhone || ""}
            onChange={this.props.handleChangePersonalInfo}
          >
            {inputProps => (
              <TextField
                id="parent-one-phone"
                name="parentOnePhone"
                label="מספר נייד הורה"
                margin="normal"
                fullWidth={true}
                classes={{ root: "input-rtl" }}
              />
            )}
          </InputMask>

          <br />

          <InputMask
            mask="999-9999999"
            value={this.props.childInfo.parentTwoPhone || ""}
            onChange={this.props.handleChangePersonalInfo}
          >
            {inputProps => (
              <TextField
                id="parent-two-phone"
                name="parentTwoPhone"
                label="מספר נייד הורה נוסף"
                margin="normal"
                fullWidth={true}
                classes={{ root: "input-rtl" }}
              />
            )}
          </InputMask>

          <br />
          <TextField
            id="parentOneEmail"
            name="parentOneEmail"
            label="כתובת דואר אלקטרוני"
            margin="normal"
            fullWidth={true}
            value={this.props.childInfo.parentOneEmail || ""}
            classes={{ root: "input-rtl" }}
            onChange={this.props.handleChangePersonalInfo}
          />
          <br />
          <TextField
            id="parentTwoEmail"
            name="parentTwoEmail"
            label="כתובת דואר אלקטרוני הורה נוסף"
            margin="normal"
            fullWidth={true}
            value={this.props.childInfo.parentTwoEmail || ""}
            classes={{ root: "input-rtl" }}
            onChange={this.props.handleChangePersonalInfo}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PersonalForm;
