import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

class Notifier extends React.Component {
  

  render() {
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.props.message }}
      />
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={message}
        autoHideDuration={7000}
        onClose={this.props.onClose}
        open={this.props.open}
        classes={{root:this.props.errorType}}
      />
    );
  }
}

export default Notifier;