import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputMask from "react-input-mask";

import Header from "../header/header";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepOne from "./steps/step-one";
import StepTwo from "./steps/step-two";
import StepThree from "./steps/step-three";
import StepFour from "./steps/step-four";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

import Axios from "axios";

import "./info-form.css";

const theme = createMuiTheme({
  palette: {
    primary: blue
  },
  typography: {
    useNextVariants: true
  }
});

class FormInfo extends Component {
  state = {
    activeStep: 0,
    activeNext: false
  };
  handleNext = () => {
    const { activeStep } = this.state;
    if (activeStep == 3) {
      this.setState({activeNext:true})
      this.props.showPreloader("");
      //console.log(this.props);
      let allInfo = {
        childInfo: { ...this.props.childInfo },
        apiFieldsPayments: { ...this.props.apiFieldsPayments },
        apiFields: { ...this.props.apiFields },
        token: this.props.token,
      };
      Axios.post("https://rishum.school/wp-json/school/v1/register", {
        data: allInfo
      })
        .then(response => {
          if(response.data.status == "1"){
            this.moveUp()
            this.props.showPreloader("hidden");
            this.props.setReceiptUrl(response.data.fileUrl);
            this.props.handleIsFinish();
          }
          else{
            //console.log("response",response)
          }
          
        })
        .catch(error => {
          //console.log("error:",error);
        });
    } else {
      this.moveUp()
      this.setState({
        activeStep: activeStep + 1
      });
      
    }
  };

  moveUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  // Similar for back and reset buttons
  handleBack = () => {
    const { activeStep } = this.state;
    this.moveUp()
    this.setState({
      activeStep: activeStep - 1
    });
  };

  render() {
    const { activeStep } = this.state;

    return (
      <div className="main-info-form">
       

        <div className="form-info-holder">
          <MuiThemeProvider theme={theme}>
            <Stepper
              activeStep={activeStep}
              classes={{ root: "steper-container" }}
            >
              <Step key={0}>
                <StepLabel className="text-steper">אישור תשלומים</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel className="text-steper"> אישור ספרייה </StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel className="text-steper"> דף תשלומים </StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel className="text-steper">
                  {" "}
                  השלמת פרטים וסיכום{" "}
                </StepLabel>
              </Step>
            </Stepper>

            <div className="steps">
              {activeStep == 0 ? (
                <StepOne
                  updateApiFields={this.props.updateApiFields}
                  apiFields={this.props.apiFields}
                  schoolInfo={this.props.schoolInfo}
                />
              ) : (
                ""
              )}
              {activeStep == 1 ? (
                <StepTwo
                  updateApiFields={this.props.updateApiFields}
                  apiFields={this.props.apiFields}
                  schoolInfo={this.props.schoolInfo}
                />
              ) : (
                ""
              )}
              {activeStep == 2 ? (
                <StepThree
                  apiFieldsPayments={this.props.apiFieldsPayments}
                  onChangePayment={this.props.onChangePayment}
                  payReqInfo={this.props.payReqInfo}
                  paySelInfo={this.props.paySelInfo}
                  updateApiFields={this.props.updateApiFields}
                  apiFields={this.props.apiFields}
                  schoolInfo={this.props.schoolInfo}
                  systemSettings={this.props.systemSettings}
                  fullPrice={this.props.fullPrice}
                />
              ) : (
                ""
              )}
              {activeStep == 3 ? (
                <StepFour
                  childInfo={this.props.childInfo}
                  handleChangePersonalInfo={this.props.handleChangePersonalInfo}
                  apiFieldsPayments={this.props.apiFieldsPayments}
                  payReqInfo={this.props.payReqInfo}
                  paySelInfo={this.props.paySelInfo}
                  loaderVisible={this.props.loaderVisible}
                />
              ) : (
                ""
              )}
              {activeStep == 4 ? (
                <h1>סיימנו</h1>
              ) : (
                ""
              )}
            </div>

            <div className="buttons-form">
              <Button
                disabled={activeStep === 0}
                onClick={this.handleBack}
                variant="contained"
                color="primary"
              >
                לשלב הקודם
              </Button>
              <Button
                disabled={
                  (activeStep === 0 &&
                    !this.props.apiFields.acceptPaymentAgree) ||
                  (activeStep === 1 &&
                    !this.props.apiFields.acceptLibraryAgree) ||
                  (activeStep === 2 && this.props.checkIsCompPay) ||
                  (activeStep === 3 &&
                    ((!this.props.childInfo.parentOnePhone &&
                      !this.props.childInfo.parentTwoPhone) ||
                    (!this.props.childInfo.parentOneEmail &&
                      !this.props.childInfo.parentTwoEmail))) ||
                    (this.state.activeNext)
                      
                }
                variant="contained"
                color="primary"
                onClick={this.handleNext}
              >
                {activeStep === 4 - 1 ? "אישור פרטים וסיום" : "הבא"}
              </Button>
            </div>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default FormInfo;
