import React, { Component } from "react";
import LoginForm from "./components/login-form/login-form";
import InfoForm from "./components/info-form/info-form";
import Thankyou from "./components/thankyou/thankyou";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

import "./App.css";
import Axios from "axios";
import Notifier from "./components/snackbar/snackbar";

class App extends Component {
  state = {
    loginFields: {
      idParent: "",
      idChild: "",
      birthOfChild: "",
    },
    schoolInfo: {},
    childInfo: {},
    payReqInfo: {},
    paySelInfo: {},
    isLogged: false,
    isFinish: false,
    errorMsg: "",
    errorOpen: false,
    errorType: "",
    apiFields: {},
    apiFieldsPayments: {},
    isPaymentsFieldFull: true,
    systemSettings: {},
    fullPrice: 0,
    modalState: false,
    fileUrl: "",
    loaderVisible: "hidden",
    receiptUrl: "",
  };

  logout = () => {
    this.setState({
      loginFields: {
        idChild: "",
        idParent: "",
        birthOfChild: "",
      },
      isLogged: false,
      isFinish: false,
    });
  };

  showPreloader = (status) => {
    this.setState({ loaderVisible: status });
  };

  isFinish = () => {
    this.setState({ isFinish: true });
  };

  openModal = (url = "") => {
    //console.log(url);
    this.setState((prevState) => ({
      modalState: !prevState.modalState,
      fileUrl: url,
    }));
  };
  onChangeInputHandler = (e) => {
    this.setState({
      loginFields: {
        ...this.state.loginFields,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      errorOpen: false,
      errorMsg: "",
    });
  };

  setReceiptUrl = (url) => {
    this.setState({ receiptUrl: url });
  };

  setFirstStateForPaymentSel = (fields) => {
    for (let field in fields) {
      this.setState({
        apiFieldsPayments: {
          ...this.state.apiFieldsPayments,
          [fields[field].paymentId]: "-1",
        },
      });
    }
  };

  setFirstStateForPaymentReq = (fields) => {
    let fullPrice = 0;
    for (let field in fields) {
      fullPrice += parseInt(fields[field].price);
      this.setState({
        apiFieldsPayments: {
          ...this.state.apiFieldsPayments,
          [fields[field].paymentId]: "1",
        },
      });
    }
    this.setState({ fullPrice: fullPrice });
  };

  handleChangePersonalInfo = (e) => {
    this.setState({
      childInfo: {
        ...this.state.childInfo,
        [e.target.name]: e.target.value,
      },
    });
  };
  getPaymentInfo = (id) => {
    let payReq = Array.from(this.state.payReqInfo);
    let paySel = Array.from(this.state.paySelInfo);
    var allPaymentsInfo = payReq.concat(paySel);

    return allPaymentsInfo.filter((pay) => {
      return pay.paymentId == id;
    });
  };
  handlePaymentChecboxChange = (e) => {
    this.setState(
      {
        apiFieldsPayments: {
          ...this.state.apiFieldsPayments,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        let fullPrice = 0;
        let full = false;
        let allPayments = this.state.apiFieldsPayments;

        for (let i in allPayments) {
          if (allPayments[i] === "-1") {
            full = true;
          } else if (allPayments[i] === "1") {
            //console.log(parseInt(this.getPaymentInfo(i)[0].price));
            fullPrice += parseInt(this.getPaymentInfo(i)[0].price);
          }
        }

        this.setState({
          isPaymentsFieldFull: full,
          fullPrice: fullPrice,
        });
      }
    );
  };

  /*
  componentWillMount(){
    this.setState({
      loginFields : {
        idChild : "212872758",
        idParent : "056651441",
        birthOfChild : "2002-06-27"
      }
    })
  }
  */
  loginValidator = (e) => {
    e.preventDefault();
    this.showPreloader("");
    Axios.post("https://rishum.school/wp-json/school/v1/auth", {
      data: this.state.loginFields,
    })
      .then((response) => {
        this.showPreloader("hidden");
        let res = response.data;
        console.log(res);
        if (res.status == "0") {
          this.setState({
            errorMsg: res.msg,
            errorOpen: true,
            errorType: "danger",
          });
        } else {
          this.setState({
            schoolInfo: res.data.school,
            childInfo: res.data.child,
            payReqInfo: res.data.paymentReqsList,
            paySelInfo: res.data.paymentSelList,
            systemSettings: res.data.systemSettings,
            token: res.data.token,
          });

          this.setFirstStateForPaymentSel(res.data.paymentSelList);
          this.setFirstStateForPaymentReq(res.data.paymentReqsList);
          this.setState({ isLogged: true });
        }
      })
      .catch((error) => {
        this.setState({
          errorMsg: "ארעה שגיאה בלתי צפויה, אנא נסו שוב מאוחר יותר",
          errorOpen: true,
          errorType: "danger",
        });
        //console.log(error);
      });
  };

  handleUpdateApiFields = (e) => {
    this.setState({
      apiFields: {
        ...this.state.apiFields,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    let { isLogged, isFinish } = this.state;
    if (!isLogged && !isFinish) {
      return (
        <div>
          <Notifier
            errorType={this.state.errorType}
            message={this.state.errorMsg}
            open={this.state.errorOpen}
            onClose={this.handleSnackbarClose}
          />
          <LoginForm
            onSubmit={this.loginValidator}
            onChange={this.onChangeInputHandler}
            elements={this.state.loginFields}
            loaderVisible={this.state.loaderVisible}
            showPreloader={this.showPreloader}
          />
        </div>
      );
    } else if (!isFinish && isLogged) {
      return (
        <React.Fragment>
          <Header
            schoolInfo={this.state.schoolInfo}
            childInfo={this.state.childInfo}
            logout={this.logout}
          />
          <InfoForm
            payReqInfo={this.state.payReqInfo}
            paySelInfo={this.state.paySelInfo}
            apiFields={this.state.apiFields}
            checkIsCompPay={this.state.isPaymentsFieldFull}
            apiFieldsPayments={this.state.apiFieldsPayments}
            onChangePayment={this.handlePaymentChecboxChange}
            updateApiFields={this.handleUpdateApiFields}
            apiFields={this.state.apiFields}
            schoolInfo={this.state.schoolInfo}
            childInfo={this.state.childInfo}
            systemSettings={this.state.systemSettings}
            fullPrice={this.state.fullPrice}
            handleChangePersonalInfo={this.handleChangePersonalInfo}
            loaderVisible={this.state.loaderVisible}
            showPreloader={this.showPreloader}
            handleIsFinish={this.isFinish}
            isFinish={this.state.isFinish}
            setReceiptUrl={this.setReceiptUrl}
            token={this.state.token}
          />
          <Footer
            modalState={this.state.modalState}
            openModal={this.openModal}
            fileUrl={this.state.fileUrl}
            schoolInfo={this.state.schoolInfo}
          />
        </React.Fragment>
      );
    } else if (isFinish && isLogged) {
      return (
        <React.Fragment>
          <Header
            schoolInfo={this.state.schoolInfo}
            childInfo={this.state.childInfo}
            logout={this.logout}
          />

          <Thankyou
            schoolInfo={this.state.schoolInfo}
            payReqInfo={this.state.payReqInfo}
            paySelInfo={this.state.paySelInfo}
            apiFieldsPayments={this.state.apiFieldsPayments}
            receiptUrl={this.state.receiptUrl}
            modalState={this.state.modalState}
            openModal={this.openModal}
            fileUrl={this.state.fileUrl}
          />
        </React.Fragment>
      );
    }
  }
}

export default App;
