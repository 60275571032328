import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import './header.css';

const Header = props => {
  return (
    <AppBar position="static" color="default">
      <div className="header-info">
        <div>
          <img
            className="logo-school"
            src={props.schoolInfo.schoolLogo}
            alt="לוגו בית הספר"
          />
        </div>
        <div>
          <span className="welcome-text">
            <b>ברוכים הבאים להורי התלמיד/ה</b>
            <div className="child-full-name">
              {props.childInfo.studentFirstName}{" "}
              {props.childInfo.studentLastName}
            </div>
            
          </span>
        </div>
        <div>
        <Button
              variant="contained"
              color="secondary"
              onClick={props.logout}
            >
              התנתק מהמערכת
            </Button>
            </div>
      </div>
    </AppBar>
  );
};

export default Header;
