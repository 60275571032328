import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";
import Parser from 'html-react-parser';

import "./payment-info.css";

class PaymentInfo extends Component {
  render() {
    let payReq = Array.from(this.props.payReqInfo);
    let paySel = Array.from(this.props.paySelInfo);
    var allPaymentsInfo = payReq.concat(paySel);

    let apiFieldsPayments = this.props.apiFieldsPayments;
    var out = Object.keys(apiFieldsPayments).map(function(data) {
      return [data, apiFieldsPayments[data]];
    });

    let paySelected = out.filter(res => {
      return res[1] == 1;
    });

    let payUnselected = out.filter(res => {
      return res[1] != 1;
    });

    return (
      <div className="column">
        <Paper className="sum-payments-paper">
          <h3>סיכום תשלומים</h3>
          <div>תשלומים שאושרו</div>
          <ul>
            {paySelected.map((res, i) => {
              let currentPay = allPaymentsInfo.filter(elem => {
                return elem.paymentId == res[0];
              });
              return (
                <li key={i}>
                  {Parser(currentPay[0].title)} - {currentPay[0].price} &#8362;
                </li>
              );
            })}
          </ul>

          <div>תשלומים שלא אושרו</div>
          <ul>
            {payUnselected.map( (res , i)=> {
              let currentPay = allPaymentsInfo.filter(elem => {
                return elem.paymentId == res[0];
              });
              return (
                <li key={i}>
                  {Parser(currentPay[0].title)} - {currentPay[0].price} &#8362;
                </li>
              );
            })}
          </ul>
        </Paper>
      </div>
    );
  }
}
export default PaymentInfo;
