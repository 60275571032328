import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputMask from 'react-input-mask'

import "./login-form.css";
import logo from '../../assets/img/misgav-logo.png';

const FormLogin = props => {
  return (
  <div className="login-bg">
  <CircularProgress size={120} className={`preloader-end ${props.loaderVisible}`} />

    <div className="main-login-form">
      <img src={logo} style={{maxWidth:"100px"}}/>
      <h2>אתר תשלומי הורים - קמפוס משגב</h2>
      <h4>לזיהוי יש להזין את הפרטים הבאים:</h4>
      <form onSubmit={props.onSubmit} className="app-login">
          <div className="elem-holder">
                <TextField
                  required={true}
                  id="parent-id"
                  name="idParent"
                  label="תעודת זהות הורה  (9 ספרות)"
                  margin="normal"
                  fullWidth={true}
                  classes={{ root: 'input-rtl' }}
                  value={props.elements.idParent}
                  onChange={props.onChange}
                />
            <br/>
                <TextField
                required
                id="child-id"
                name="idChild"
                label="תעודת זהות ילד  (9 ספרות)"
                margin="normal"
                fullWidth={true}
                classes={{ root: 'input-rtl' }}
                value={props.elements.idChild} 
                onChange={props.onChange}
              />
            <br/>
            <br/>
            <TextField
              id="child-birth-date"
              name="birthOfChild"
              onChange={props.onChange}
              value={props.elements.birthOfChild}
              type="date"
              margin="normal"
              fullWidth={true}
              required
            />
            <br />
            <Button type="submit" color="primary" variant="contained">
              התחבר למערכת
            </Button>
          </div>
      </form>
    </div>
  </div>
  );
};

export default FormLogin;
