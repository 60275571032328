import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import "./step-two.css";

class FormStepTwo extends Component {
  render() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.schoolInfo.schoolSifria
          }}
        />
        <hr />
        <div className="approve-check">
          <RadioGroup name="acceptLibraryAgree">
            <FormControlLabel
              value="1"
              control={
                <Radio
                  onChange={this.props.updateApiFields}
                  checked={this.props.apiFields.acceptLibraryAgree === "1"}
                />
              }
              label="קראתי ואני מאשר את הכתוב לעיל"
            />
            <FormControlLabel
              value="0"
              control={
                <Radio
                  onChange={this.props.updateApiFields}
                  checked={this.props.apiFields.acceptLibraryAgree === "0"}
                />
              }
              label="קראתי ואינני מאשר"
            />
          </RadioGroup>
        </div>
      </div>
    );
  }
}

export default FormStepTwo;
