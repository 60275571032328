import React from "react";

import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import "./modal.css";

class DrawerFiles extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.modalState}
        className="modal-files-holder"
        onBackdropClick={this.props.openModal}
      >
        <Paper className="modal-files">
          <div className="close-modal">
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.openModal}
            >
              X
            </Button>
          </div>
          <embed
            src={this.props.fileUrl}
            type="application/pdf"
            width="100%"
            height="400px"
          />
        </Paper>
      </Modal>
    );
  }
}
export default DrawerFiles;
