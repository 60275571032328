import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import "./step-one.css";

class FormStepOne extends Component {
  render() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.schoolInfo.schoolPayment
          }}
        />
        <hr />
        <div className="approve-check">
          <FormControlLabel
            control={
              <Checkbox
                onChange={this.props.updateApiFields}
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                value="1"
                name="acceptPaymentAgree"
                color="primary"
                checked={this.props.apiFields.acceptPaymentAgree ? "checked" : ""}
              />
            }
            label="קראתי ואני מאשר את הכתוב לעיל"
          />
        </div>
      </div>
    );
  }
}

export default FormStepOne;
