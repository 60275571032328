import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Modal from "../modal/modal";

import "./footer.css";

class Footer extends React.Component {
  state = {
    drawerOpen: false
  };

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  };

  render() {
    return (
      <div className="footer">
        <Modal
          modalState={this.props.modalState}
          fileUrl={this.props.fileUrl}
          openModal={this.props.openModal}
        />
        <div>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              this.props.openModal(this.props.schoolInfo.schoolVaadFile)
            }
          >
            איגרת יו"ר הועד
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              this.props.openModal(this.props.schoolInfo.schoolMangerFile)
            }
          >
            איגרת מנהל בית הספר
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              this.props.openModal(this.props.schoolInfo.schoolMapFile)
            }
          >
            מפת בית הספר
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              this.props.openModal(this.props.schoolInfo.schoolHanacotFile)
            }
          >
            טופס ועדת הנחות
          </Button>
        </div>
      </div>
    );
  }
}

export default Footer;
