import React from "react";

import PaymentInfo from "../info-form/payment-info/payment-info";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Modal from "../modal/modal";

import "./thankyou.css";

const Thankyou = (props) => (
  <div className="thankyou">
    <Modal
      modalState={props.modalState}
      fileUrl={props.fileUrl}
      openModal={props.openModal}
    />
    <h1>תודה שהשלמתם את תהליך ההרשמה</h1>

    <div className="thankyou-info-container">
      <PaymentInfo
        payReqInfo={props.payReqInfo}
        paySelInfo={props.paySelInfo}
        apiFieldsPayments={props.apiFieldsPayments}
      />
      <div className="column thankyou-buttons">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            props.openModal(props.receiptUrl);
          }}
        >
          צפייה והדפסה של דף אישור תשלומים
        </Button>
        <div style={{ marginTop: "20px" }}></div>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            props.openModal(props.schoolInfo.schoolLockerFile);
          }}
        >
          קישור להשכרת לוקרים - מבנה אביב בלבד
        </Button>
        <div style={{ marginTop: "20px" }}></div>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            props.openModal(props.schoolInfo.schoolLockerFileAdd);
          }}
        >
          קישור להשכרת לוקרים - שאר המבנים
        </Button>
      </div>
    </div>
  </div>
);

export default Thankyou;
