import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Parser from 'html-react-parser';


import "./step-three.css";
import { Button } from "@material-ui/core";

class FormStepThree extends Component {

  render() {
    let payReq = Array.from(this.props.payReqInfo);
    let paySel = Array.from(this.props.paySelInfo);
    return (
      <div>
        <h2>תשלומי חובה</h2>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">שם התשלום</TableCell>
                <TableCell align="right">סה"כ לתשלום</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {payReq.map( (res,i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align="right">
                      <div>
                        {Parser(res.title)}
                        <Tooltip title={Parser(res.desc)} classes={{ tooltip: "info-window" }}>
                          <span className="tooltip-info">?</span>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="right">{res.price} &#8362;</TableCell>
                    <TableCell align="right">
                        <RadioGroup price={res.price} name="gender1" className="radio-payment">
                          <FormControlLabel
                            value="female"
                            control={<Radio disabled={true} checked={true} />}
                            label="מאשר"
                            
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio disabled={true}/>}
                            label="לא מאשר"
                          />
                        
                        </RadioGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>

        <h2>תשלומי רשות</h2>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">שם התשלום</TableCell>
                <TableCell align="right">סה"כ לתשלום</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {paySel.map((res,i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align="right">
                      <div>
                        {Parser(res.title)}
                        <Tooltip title={Parser(res.desc)} classes={{ tooltip: "info-window" }}>
                          <span className="tooltip-info">?</span>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="right">{res.price} &#8362;</TableCell>
                    <TableCell align="right">
                        <RadioGroup price={res.price} name={res.paymentId}  classes={{root:"radio-payment"}}>
                          <FormControlLabel
                            value="1"
                            control={<Radio onChange={this.props.onChangePayment} checked={this.props.apiFieldsPayments[res.paymentId] === "1"} />}
                            label="מאשר"
                            
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio onChange={this.props.onChangePayment} checked={this.props.apiFieldsPayments[res.paymentId] === "0"}/>}
                            label="לא מאשר"
                          />
                        
                        </RadioGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <Paper className="subtotal">סה"כ: {this.props.fullPrice} &#8362;</Paper>
        <div className="msg-bottom">
          {this.props.systemSettings.msgPayment}
        </div>
      </div>
    );                    
  }
}

export default FormStepThree;
